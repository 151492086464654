<template>
    <div>
        <v-toolbar dense color="primary" flat dark>
            <v-toolbar-title>Espera Consulta</v-toolbar-title>
            <v-spacer/>
            <v-btn @click="busca">
                Buscar
            </v-btn>
        </v-toolbar>
        <v-sheet class="pa-2" elevation="2">
            <v-row no-gutters>
                <v-col md=3 cols="12">
                    <v-date-picker
                        v-model="fechaPicker"
                        range
                        full-width
                        no-title
                        scrollable
                        :max="estaFecha"
                        locale="es-mx"
                    />
                </v-col>
                <v-col md=9 cols="12">

                </v-col>
            </v-row>
            <v-data-table
                        class="mt-2"
                        :headers="headers"
                        :items="movimientosTodos"
                        hide-default-footer
                        item-key="id"
                        :loading="loadingDatos"
                        dense
                        :server-items-length='movimientosTotal'
                        disable-pagination
                        group-by="especialidad"
                        :show-group-by='true'
                    >
                        <!--
                        <template v-slot:[`item.FechaDe`]="{ item }">
                            <div class="text-caption">
                                DE: {{item.FechaDe}}<br/>
                                A: {{item.FechaA}}
                            </div>
                        </template>

                        <template v-slot:[`item.nombres`]="{ item }">
                            <div class="text-caption">({{item.cip}}) {{item.nombres}} {{item.apellidoPaterno}} {{item.apellidoMaterno}}<br/>{{item.curp}}</div>
                        </template>
                        -->

                    </v-data-table>
        </v-sheet>



    </div>
</template>

<script>
export default {
    mounted(){
        //this.db.tipo = 'i'
        //this.areaServicio = 'a'
        this.fechaPicker = [
            this.moment(new Date()).format("YYYY-MM-DD"),
            this.moment(new Date()).format("YYYY-MM-DD"),
        ]
    },
    data:()=>({
        loadingDatos:false,
        db:{},
        fechaPicker:[],

        headers:[
            { text: 'Especialidad', value: 'especialidad', width:'100px'},
            { text: 'Llega', value: 'hora_recepcion', align:'center', groupable:false},
            { text: 'Atiende', value: 'hora_atiende', align:'center', groupable:false},
            { text: 'Espera', value: 'espera', align:'center', width:'40px', groupable:false},
            { text: 'C.I.P.', value: 'title_id', align:'center'},
            { text: 'sexo', value: 'sexo', align:'center', width:'40px'},
        ],
        movimientosTodos:[],
        movimientosTotal:0,
    }),
    methods:{
        async busca(){
            this.loadingDatos = true
            try{
                this.loadingDatos = false
                const req = await this.$http({
                    method:"POST",
                    url:"/reportes/reporteEsperaConsulta",
                    params:this.db,
                })
                this.loadingDatos=false
                console.log(req.data)
                this.movimientosTodos=req.data.root
                this.movimientosTotal=req.data.total

            } catch(err){
                this.$store.dispatch('security/revisaError',err)
                this.loadingDatos = false
            }
        },
        colorArea(id){
            let text = 'black'
            if ([8,7,6,5,14,13].includes(id)){
                text = 'green'
            }
            if ([1,2,3,9,10,11].includes(id)){
                text = 'orange'
            }

            return (text)
        },
        async getAreas(){
            try{
                let req = await this.$http({
                    method:'GET',
                    url:'/catalogos/areas'
                })
                this.areas = req.data
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async getServicios(){
            try{
                let req = await this.$http({
                    method:'GET',
                    url:'/catalogos/servicios'
                })
                this.servicios = req.data
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async getTiposAlta(){
            try{
                let req = await this.$http({
                    method:'GET',
                    url:'/catalogos/tiposaltas'
                })
                this.tiposAlta = req.data
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    computed:{
        estaFecha(){
            return (this.moment(new Date()).format('YYYY-MM-DD'))
        },
    },
    watch:{
        fechaPicker(){
            let far=[]
            // this.fechaArr = []
            if(this.fechaPicker.length > 0){
                this.fechaPicker.forEach(fecha => {
                    far.push(new Date(fecha+'T00:00:00'))
                })

                this.db.fechaDe = this.moment(new Date(Math.min.apply(null,far))).format("DD-MM-YYYY")
                this.db.fechaA = this.moment(new Date(Math.max.apply(null,far))).format("DD-MM-YYYY")
            }
        },
    },
}
</script>

<style>

</style>